import $ from 'jquery'

function initScripts(e) {
  //Work section - display image on hover
  $('.home-work__single:first-of-type').addClass('is-hovered');
  $( ".js-work-single-hover" ).hover(
    function() {
      $('.js-work-single-image').removeClass('is-hovered');
      var imageItem = $( this ).data('hover');
      $('#'+imageItem).addClass( 'is-hovered' );
      //$( this ).addClass('is-hovered');
      $('.home-work__single').removeClass('is-hovered');
    }, function() {
      var id = $('.home-work__image.is-hovered').attr('id');
      $('.home-work__single[data-hover='+id+']').addClass( 'is-hovered' )
      //$('.js-work-single-image').removeClass('is-hovered');
      //$('#1').addClass( 'is-hovered' );
      //$( this ).removeClass('is-hovered');
    }
  );
  $( ".home-work__bg--left" ).click(
    function() {
      var id = $('.home-work__image.is-hovered').attr('id');
      id--;
      var link = $('.home-work__single[data-hover='+id+']');
      if(id != 0) {
        $('.home-work__single').removeClass('is-hovered');
        $('.js-work-single-image').removeClass('is-hovered');
        $('#'+id).addClass( 'is-hovered' );
        link.addClass( 'is-hovered' );
      }
    }
  );
  $( ".home-work__bg--right" ).click(
    function() {
      var lastId = $('.home-work__image').last().attr('id');
      var id = $('.home-work__image.is-hovered').attr('id');
      id++;
      var link = $('.home-work__single[data-hover='+id+']');
      //console.log(id);
      if(id <= lastId) {
        $('.home-work__single').removeClass('is-hovered');
        $('.js-work-single-image').removeClass('is-hovered');
        $('#'+id).addClass( 'is-hovered' );
        link.addClass( 'is-hovered' );
      }
    }
  );
  // Passive event listeners
  jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
  };
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
  };
}

$(document).ready(initScripts)
